import React from 'react';
import addwishRowQuery from './AddwishRowQuery.gql';
import { useQuery } from 'react-apollo';
import { RowProducts } from '../ProductRow/RowProducts';

const AddwishRow = ({ data, id, slider = false, title = '', ...rest }) => {
  const articleNumbers = (data?.items || []).map(item => item.productNumber);
  const result = useQuery(addwishRowQuery, {
    variables: {
      articleNumbers: articleNumbers
    },
    skip: articleNumbers.length === 0
  });

  //const products = result?.data?.products;

  const products =
    result?.data?.products.map(product => {
      return {
        ...product,
        addwishData: data?.items.find(
          item => item.productNumber === product.articleNumber
        ) // Make sure to include the addwishData to enable click tracking
      };
    }) || [];

  if (products && products.length > 0)
    return (
      <RowProducts
        category={{ products: { result: products } }}
        slider={slider}
        title={title}
        listName={'addwish-rec:' + id}
        {...rest}
      />
    );

  return null;
};

export default AddwishRow;
